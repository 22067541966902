import React from 'react'
import { css } from 'styled-components'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel'

import Img from 'gatsby-image'

import HeroButtons from './HeroButtons'

import { mediaQueries, colors, space, boxShadows, fontSizes, breakpoints } from '../utils/tokens'

const HeroHalfBackgroundCarousel = ({ hero, buttonGroup, height }) => {
  return (
    <section
      css={css({
        display: `flex`,
        flexDirection: `column-reverse`,
        [mediaQueries.md]: {
          display: `grid`,
          gridTemplateColumns: `1fr 1fr`,
        },
      })}
    >
      <div
        css={css({
          display: `flex`,
          flexDirection: `column`,
          padding: `${space[5]}px ${space[4]}px`,
          justifyContent: `center`,
          [mediaQueries.xl]: {
            padding: `${space[6]}px ${space[6]}px`,
            maxWidth: `${breakpoints.md}`,
            margin: `0 auto`,
          },
        })}
      >
        <h1
          css={css({
            marginBottom: `${space[3]}px`,
          })}
        >
          {hero.subtitle && (
            <span
              css={{
                display: `block`,
                fontSize: fontSizes[3],
                color: colors.primaryBrand,
              }}
            >
              {hero.subtitle.internal.content}
            </span>
          )}
          <span
            css={css({
              fontWeight: `bold`,
              [mediaQueries.xxl]: {
                fontSize: fontSizes[5],
              },
            })}
          >
            {hero.title}
          </span>
        </h1>
        {hero.tagline && (
          <div
            dangerouslySetInnerHTML={{
              __html: hero.tagline.childMarkdownRemark.html,
            }}
            css={css({
              fontSize: fontSizes[2],
              lineHeight: `1.6`,
              [mediaQueries.xxl]: {
                fontSize: fontSizes[3],
              },
            })}
          />
        )}
        <div
          css={css({
            marginTop: `${space[4]}px`,
          })}
        >
          {buttonGroup ? (
            buttonGroup
          ) : (
            <HeroButtons
              callToActionUrl={hero.callToActionUrl}
              callToActionText={hero.callToActionText}
              callToActionUrlSecondary={hero.callToActionUrlSecondary}
              callToActionTextSecondary={hero.callToActionTextSecondary}
            />
          )}
        </div>
      </div>
      <div
        css={css({
          position: `relative`,
        })}
      >
        <CarouselProvider
          totalSlides={hero.backgroundCarousel.length}
          naturalSlideHeight={1800}
          naturalSlideWidth={1440}
          css={css({
            [mediaQueries.xxl]: {
              overflow: `hidden`,
              height: height || `100vh`,
            },
          })}
        >
          <Slider>
            {hero.backgroundCarousel.map((card, id) => {
              const isFirst = id === 0
              return (
                <Slide key={card.id}>
                  {card.media.hdFluid && (
                    <Img
                      fluid={card.media.hdFluid}
                      alt={card.media.title}
                      fadeIn={false}
                      loading={isFirst ? 'eager' : 'lazy'}
                      css={css({
                        position: `absolute !important`,
                        width: `100%`,
                        height: `100%`,
                      })}
                    />
                  )}
                  <div
                    css={css({
                      position: `relative`,
                      width: `100%`,
                      height: `100%`,
                      top: 0,
                      left: 0,
                      '::before': {
                        content: `""`,
                        position: `absolute`,
                        bottom: 0,
                        width: `100%`,
                        height: `${space[7]}px`,
                        backgroundImage: `linear-gradient(-180deg,rgba(0,0,0,0) 2%,rgb(255, 255, 255) 100%) !important;`,
                      },
                      [mediaQueries.lg]: {
                        height: height || `100vh`,
                        '::before': {
                          width: 0,
                        },
                      },
                    })}
                  >
                    <div
                      css={css({
                        position: `absolute`,
                        bottom: `${space[2]}px`,
                        left: `${space[4]}px`,
                        maxWidth: `50%`,
                        [mediaQueries.lg]: {
                          bottom: `${space[5]}px`,
                          left: `${space[5]}px`,
                        },
                      })}
                    >
                      <span
                        css={css({
                          fontSize: fontSizes[2],
                          color: colors.base,
                          fontWeight: `500`,
                          [mediaQueries.lg]: {
                            fontSize: fontSizes[2],
                            backgroundColor: colors.white,
                            boxShadow: boxShadows.card,
                            padding: `${space[1]}px ${space[3]}px`,
                            borderRadius: `4px`,
                          },
                        })}
                      >
                        {card.title}
                      </span>
                    </div>
                  </div>
                </Slide>
              )
            })}
          </Slider>
          <DotGroup
            css={css({
              position: `absolute`,
              top: `${space[3]}px`,
              left: `${space[4]}px`,
              [mediaQueries.lg]: {
                top: `calc(${space[5]}px + ${space[3]}px)`,
                left: `${space[4]}px`,
              },
              '.carousel__dot': {
                height: `12px`,
                width: `12px`,
                padding: 0,
                backgroundColor: `unset`,
                borderRadius: `100%`,
                border: `1px solid ${colors.base}`,
                marginRight: `${space[2]}px`,
                ':disabled': {
                  backgroundColor: colors.base,
                },
              },
            })}
          />
          <div
            css={css({
              position: `absolute`,
              bottom: `${space[3]}px`,
              right: `${space[3]}px`,
              display: `grid`,
              gridTemplateColumns: `1fr 1fr`,
              gridGap: `${space[3]}px`,
              [mediaQueries.lg]: {
                bottom: `${space[5]}px`,
                right: `${space[6]}px`,
              },
            })}
          >
            <ButtonBack
              css={css({
                ...buttonStyle,
              })}
            >
              <IoIosArrowBack />
            </ButtonBack>
            <ButtonNext
              css={css({
                ...buttonStyle,
              })}
            >
              <IoIosArrowForward />
            </ButtonNext>
          </div>
        </CarouselProvider>
      </div>
    </section>
  )
}

const buttonStyle = {
  borderRadius: `100%`,
  border: `none`,
  width: `48px`,
  height: `48px`,
  fontSize: fontSizes[3],
  backgroundColor: colors.white,
  boxShadow: `${boxShadows.card}`,
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
}

export default HeroHalfBackgroundCarousel