import React from 'react'
import { css } from 'styled-components'
import { navigate } from 'gatsby'

import Button, { QuoteButton } from './Button'

import {
  space, breakpoints,
} from '../utils/tokens'

const HeroButtons = ({
  callToActionUrl,
  callToActionText,
  callToActionUrlSecondary,
  callToActionTextSecondary,
  ...rest
}) => {
  const isUrlTypeform = url => {
    // if url is in form of https://printaworld.typeform.com/to/a2313 "
    // we can do this
    const parsed = url.split('.')

    return parsed[1] === `typeform`
  }

  const showCallToAction = () => {
    if (isUrlTypeform(callToActionUrl)) {
      return (
        <QuoteButton
          url={callToActionUrl}
          css={css({
            width: `100%`,
          })}
        >
          {callToActionText || `START YOUR PROJECT`}
        </QuoteButton>
      )
    } else {
      return (
        <Button
          onClick={() => navigate(callToActionUrl)}
          css={css({
            width: `100%`,
          })}
        >
          {callToActionText || `START YOUR PROJECT`}
        </Button>
      )
    }
  }

  const showCallToActionSecondary = () => {
    if (isUrlTypeform(callToActionUrlSecondary)) {
      return (
        <QuoteButton
          url={callToActionUrlSecondary}
          css={css({
            width: `100%`,
            marginTop: `${space[2]}px`,
          })}
        >
          {callToActionTextSecondary || `START YOUR PROJECT`}
        </QuoteButton>
      )
    } else {
      return (
        <Button
          dark
          onClick={() => navigate(callToActionUrlSecondary)}
          css={css({
            width: `100%`,
            marginTop: `${space[2]}px`,
          })}
        >
          {callToActionTextSecondary}
        </Button>
      )
    }
  }

  return (
    <div
      css={css({
        maxWidth: `${breakpoints.xs}`,
      })}
      {...rest}
    >
      {callToActionUrl && showCallToAction()}
      {callToActionUrlSecondary && showCallToActionSecondary()}
    </div>
  )
}

export default HeroButtons