import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { css } from 'styled-components'

import Button, { QuoteButton } from '../components/Button'

import {
  mediaQueries,
  space,
  breakpoints,
  fontSizes,
  colors,
} from '../utils/tokens'
import { navigate } from 'gatsby'

const HeroTwoColumns = (props) => {
  const { hero, featuredCompanies, typeform } = props

  const sources = [
    hero.backgroundPhoto.mobileFluid,
    {
      ...hero.backgroundPhoto.fluid,
      media: `(min-width: ${breakpoints.lg})`,
    },
  ]

  const showButton = () => {
    if(typeform) {
      return (
        <div>
          <QuoteButton
            url={typeform}
            css={css({
              width: `auto`,
            })}
          >
            {hero.callToActionText || `START YOUR PROJECT`}
          </QuoteButton>
          {hero.callToActionUrlSecondary && (
            <Button
              dark
              onClick={() => navigate(hero.callToActionUrlSecondary)}
              css={css({
                width: `100%`,
                marginTop: `${space[2]}px`,
              })}
            >
              {hero.callToActionTextSecondary}
            </Button>
          )}
        </div>
      )
    } else if(hero.callToActionUrl)  {
      return (
        <div>
          <Button
            onClick={() => navigate(hero.callToActionUrl)}
            css={css({
              width: `auto`,
            })}
          >
            {hero.callToActionText || `START YOUR PROJECT`}
          </Button>
          {hero.callToActionUrlSecondary && (
            <Button
              dark
              onClick={() => navigate(hero.callToActionUrlSecondary)}
              css={css({
                width: `100%`,
                marginTop: `${space[2]}px`,
              })}
            >
              {hero.callToActionTextSecondary}
            </Button>
          )}
        </div>
      )
    }
  }
  return (
    <section
      css={{
        [mediaQueries.lg]: {
          padding: `${space[5]}px 0`,
        },
      }}
    >
      <div
        css={css({
          maxWidth: breakpoints.xl,
          margin: `auto`,
          padding: `${space[4]}px ${space[4]}px ${space[5]}px`,
          display: `flex`,
          flexDirection: `column-reverse`,
          [mediaQueries.lg]: {
            display: `grid`,
            gridTemplateColumns: `auto 1fr`,
            gridGap: `${space[4]}px`,
          },
        })}
      >
        <div
          css={css({
            [mediaQueries.lg]: {
              display: `flex`,
              flexDirection: `column`,
              alignSelf: `center`,
              alignItems: `flex-start`,
              maxWidth: `${breakpoints.sm}`,
            },
          })}
        >
          <h1
            css={css({
              textTransform: `uppercase`,
              letterSpacing: `1.5px`,
              marginBottom: `${space[2]}px`,
            })}
          >
            {hero.subtitle && (
              <span
                css={css({
                  fontSize: fontSizes[2],
                  display: `block`,
                  color: colors.primaryBrand,
                  [mediaQueries.lg]: {
                    fontSize: fontSizes[2],
                  },
                })}
              >
                {hero.subtitle.internal.content}&nbsp;
              </span>
            )}
            <span
              css={css({
                fontSize: fontSizes[4],
                fontWeight: `bold`,
                [mediaQueries.lg]: {
                  fontSize: fontSizes[5],
                },
              })}
            >
              {hero.title}
            </span>
          </h1>
          {hero.tagline && (
            <div
              dangerouslySetInnerHTML={{
                __html: hero.tagline.childMarkdownRemark.html,
              }}
              css={css({
                fontSize: fontSizes[3],
                lineHeight: 1.2,
                marginTop: `${space[0]}px`,
                marginBottom: `${space[4]}px`,
              })}
            />
          )}
          <div
            css={css({
              display: `flex`,
              flexDirection: `column`,
              alignItems: `center`,
            })}
          >
            {showButton()}
            <span
              css={css({
                display: `block`,
                maxWidth: `150px`,
                marginTop: `${space[2]}px`,
                fontStyle: `italic`,
                textAlign: `center`,
                fontSize: fontSizes[1],
              })}
            >
              Talk to a real person. No signup required.
            </span>
          </div>
          {featuredCompanies && (
            <div
              css={css({
                marginTop: `${space[5]}px`,
              })}
            >
              <div
                css={css({
                  display: `grid`,
                  gridGap: `${space[4]}px`,
                  gridTemplateColumns: `repeat(3, 80px)`,
                  justifyContent: `center`,
                  alignItems: `center`,
                })}
              >
                {featuredCompanies.map((company, id) => {
                  return (
                    <div key={id}>
                      <img
                        src={company.file.url}
                        alt={company.title}
                        css={css({
                          filter: `grayscale(1)`,
                          opacity: 0.75,
                          width: `auto`,
                          margin: `auto`,
                        })}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
        {hero.backgroundPhoto && hero.backgroundCarousel ? (
          <div
            css={css({
              marginBottom: `${space[6]}px`,
              position: `relative`,
              [mediaQueries.lg]: {
                marginBottom: 0,
              },
            })}
          >
            <Img
              fluid={sources}
              alt={hero.backgroundPhoto.title}
              css={css({
                width: `90%`,
              })}
            />
            <div
              css={css({
                position: `absolute`,
                top: 0,
                width: `100%`,
                height: `100%`,
              })}
            >
              {hero.backgroundCarousel.map((carouselCard, id) => {
                if (id > 2) {
                  return null
                }
                return (
                  <div
                    key={id}
                    css={css({
                      position: `absolute !important`,
                      width: `30%`,
                      right: 0,
                      padding: `${space[2]}px 0 ${space[2]}px ${space[2]}px`,
                      backgroundColor: colors.white,
                      ':nth-child(1)': {
                        width: `25%`,
                        transform: `translate(25%,-25%)`,
                        bottom: 0,
                        zIndex: 1,
                      },
                      ':nth-child(2)': {
                        width: `40%`,
                        transform: `translateY(50%)`,
                        bottom: 0,
                      },
                      [mediaQueries.lg]: {
                        padding: `${space[3]}px`,
                      },
                    })}
                  >
                    <Img
                      key={id}
                      title={carouselCard.media.title}
                      fluid={carouselCard.media.fluid}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        ) : (
          hero.backgroundPhoto && (
            <div
              css={css({
                marginBottom: `${space[4]}px`,
                [mediaQueries.lg]: {
                  marginBottom: 0,
                },
              })}
            >
              <Img
                fluid={hero.backgroundPhoto.fluid}
                alt={hero.backgroundPhoto.title}
              />
            </div>
          )
        )}
      </div>
    </section>
  )
}

export default HeroTwoColumns

HeroTwoColumns.propTypes = {
  hero: PropTypes.object,
  featuredCompanies: PropTypes.arrayOf(PropTypes.object),
  typeform: PropTypes.string,
}